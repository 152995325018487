import { Checkbox, Radio } from '@mui/joy';

export const MultiChoiceSelection = ({
  label,
  value,
  selected,
  onChange,
  disabled,
  max_responses_count
}) => {

  if (max_responses_count == 1) return (
    <Radio
      checked={selected}
      label={label}
      value={value}
      onChange={onChange}
    />
  );

  return (
    <Checkbox
      checked={selected}
      label={label}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
