import { FormHelperText, Stack } from '@mui/joy';
import { useCallback, useState } from 'react';

import { DANGER_COLOUR } from 'lib/Enum';
import { MultiChoiceSelection } from './MultiChoiceSelection';

export const MultiChoice = (props) => {
  const {
    question: {
      question_id,
      options,
      max_responses_count
    },
    locale,
    onChange,
    responseValue,
    error,
    singleColumn
  } = props;

  const [selected, setSelected] = useState(
    responseValue === '#BLANK#' ? [] : responseValue
  );

  const maxSelectedReached = max_responses_count == selected.length;
  const hasError = error?.question_id === question_id;

  const onUpdate = useCallback(
    (event) => {
      const { value, checked } = event.target;

      // only select one if max_responses_count is 1
      if (max_responses_count === 1) {
        setSelected([value]);
        onChange([value]);
        return;
      }

      const newSelected = checked
        ? [...selected, value]
        : selected.filter((item) => item !== value);

      setSelected(newSelected);
      onChange(newSelected);
    },
    [onChange, selected, max_responses_count]
  );

  return (
    <div
      style={{ width: '100%' }}
      role={max_responses_count == 1 ? 'radiogroup' : 'group'}
    >
      <Stack
        component={'ul'}
        direction={{ xs: 'column' }}
        spacing={{ xs: 1, md: 2 }}
        aria-labelledby={`question-${question_id}-label`}
        sx={{
          display: 'grid',
          padding: 0,
          margin: 0,
          listStyle: 'none',
          gridTemplateColumns: { md: singleColumn ? '1fr'  : 'repeat(2, 1fr)' },
          gridGap: '1rem',
          alignItems: { md: 'start' },
          mb: { xs: 1, md: 2 }
        }}
      >
        {options.map(({ label, value }) => (
          <li
            key={value}
            data-component={`multi-choice-option-${value}`}
            style={{
              lineHeight: 'normal',
              marginTop: '0'
            }}
          >
            <MultiChoiceSelection
              selected={selected?.includes(value)}
              label={label?.[locale] || label?.en}
              value={value}
              onChange={onUpdate}
              disabled={maxSelectedReached && !selected?.includes(value)}
              max_responses_count={max_responses_count}
            />
          </li>
        ))}
      </Stack>

      <FormHelperText
        sx={{ color: DANGER_COLOUR._500 }}
        aria-live={'polite'}
      >
        {hasError && error?.message}
      </FormHelperText>
    </div>
  );
};
