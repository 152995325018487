import * as Sentry from '@sentry/browser';

import { Box } from '@mui/joy';
import { useCallback } from 'react';
import { AccordionMatrix } from './AccordionMatrix';
import { TableMatrix } from './TableMatrix';

export const Matrix = (props) => {
  const {
    question: {
      question_id,
      options,
      statements
    },
    locale,
    responseValue,
    error,
    t,
    onChange,
  } = props;

  const displayErrorMessage = error?.question_id === question_id;

  const errorMessage = t('Please select an answer for each statement.');

  const checkForErrors = useCallback(
    (row_id) => {
      if (error?.serverError) {
        if (error?.message) {
          return error?.message?.find(({ statement_id }) => statement_id == row_id) != undefined;
        } else {
          // ensure we capture the error for debugging
          Sentry.captureMessage(JSON.stringify(error.serverError));
          return;
        }

      } else {
        return error?.question_id === question_id;
      }
    },
    [error, question_id]
  );

  return (
    <>
      <Box
        sx={{
          display: { xs: 'block', md: 'none' },
          width: '100%'
        }}
      >
        <AccordionMatrix
          question_id={question_id}
          statements={statements}
          options={options}
          locale={locale}
          responseValue={responseValue}
          displayErrorMessage={displayErrorMessage}
          errorMessage={errorMessage}
          onChange={onChange}
          checkForErrors={checkForErrors}
        />
      </Box>
      <Box
        sx={{
          display: { xs: 'none', md: 'block' },
          width: '100%'
        }}
      >
        <TableMatrix
          question_id={question_id}
          statements={statements}
          options={options}
          locale={locale}
          responseValue={responseValue}
          displayErrorMessage={displayErrorMessage}
          errorMessage={errorMessage}
          onChange={onChange}
          checkForErrors={checkForErrors}
        />
      </Box>
    </>
  );
};
