
import { FormHelperText, Stack, Table, Typography } from '@mui/joy';
import { DANGER_COLOUR, SYSTEM_COLOR } from 'lib/Enum';
import { useSelectedOptions } from './useSelectedOptions';
import { TableMatrixOption } from './TableMatrixOption';

export const TableMatrix = (props) => {
  const {
    question_id,
    options,
    statements,
    locale,
    responseValue,
    displayErrorMessage,
    errorMessage,
    onChange,
    checkForErrors
  } = props;

  const {
    selected,
    onSelectMatrixOption
  } = useSelectedOptions(responseValue, onChange);

  const errorBorder = {
    'borderLeft': `2px solid ${DANGER_COLOUR._500}`
  };

  return (
    <Stack
      spacing={2}
    >
      <Table
        stripe={'odd'}
        aria-labelledby={`question-${question_id}-label`}
      >
        <thead>
          <tr>
            <th
              aria-hidden={'true'}
              style={{
                width: '20%',
                backgroundColor: 'unset',
              }}
            />
            {options.map(({ label, value }) => (
              <th
                key={value}
                style={{
                  whiteSpace: 'unset',
                  textAlign: 'center',
                  height: '60px',
                  verticalAlign: 'top',
                  backgroundColor: 'unset',
                  padding: '0.5rem 0.25rem'
                }}
              >
                <Typography
                  sx={{
                    fontSize: '0.75rem', // 12px to fit based on common customer max options (7 or 8)
                    wordWrap: 'break-word'
                  }}
                >
                  {label?.[locale]}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {statements.map(({ name, id }) => {
            const hasError = checkForErrors(id);

            return (
              <tr
                key={id}
                role={'radiogroup'}
                aria-labelledby={`matrix-${id}-label`}
                id={`matrix-options-${id}`}
              >
                <td style={{ ...hasError && errorBorder }}>
                  <Typography
                    id={`matrix-${id}-label`}
                    sx={{
                      color: SYSTEM_COLOR.TEXT_BLACK,
                      fontSize: '0.875rem',
                    }}
                  >
                    {name?.[locale] || name?.en}
                  </Typography>
                </td>

                {options.map(({ value, label }) => {
                  const checked = selected.some(({ statement_id, response_value }) =>
                    statement_id == id && response_value == value);

                  return (
                    <td key={value}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <TableMatrixOption
                          id={id}
                          checked={checked}
                          value={value}
                          label={label?.[locale] || label?.en}
                          onSelectMatrixOption={onSelectMatrixOption}
                        />
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <FormHelperText
        sx={{ color: DANGER_COLOUR._500 }}
        aria-live={'polite'}
      >
        {displayErrorMessage && errorMessage}
      </FormHelperText>
    </Stack>
  );
};
