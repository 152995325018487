import { FormControl, FormHelperText, RadioGroup, Stack } from '@mui/joy';
import { useMemo } from 'react';
import {
  SVGStronglyDisagree,
  SVGDisagree,
  SVGSomewhatDisagree,
  SVGSomewhatAgree,
  SVGAgree,
  SVGStronglyAgree,
  SVGDontKnow
} from 'icons/survey_taking';
import { SmileyOptOutOption } from './SmileyOptOutOption';
import { SmileyOption } from './SmileyOption';

const ZERO_TO_THREE_ICONS = {
  [0]: SVGDisagree,
  [1]: SVGDontKnow,
  [2]: SVGAgree
};

const ZERO_TO_FIVE_ICONS = {
  [0]: SVGStronglyDisagree,
  [1]: SVGDisagree,
  [2]: SVGDontKnow,
  [3]: SVGAgree,
  [4]: SVGStronglyAgree
};

const ZERO_TO_SIX_ICONS = {
  [0]: SVGStronglyDisagree,
  [1]: SVGDisagree,
  [2]: SVGSomewhatDisagree,
  [3]: SVGSomewhatAgree,
  [4]: SVGAgree,
  [5]: SVGStronglyAgree
};

// expects the opt-out option to be removed before this is called
const GET_ICON_BY_SMILEY_INDEX = (number_of_smilies, index) => {
  switch (number_of_smilies) {
    case 3:
      return ZERO_TO_THREE_ICONS[index];
    case 5:
      return ZERO_TO_FIVE_ICONS[index];
    case 6:
      return ZERO_TO_SIX_ICONS[index];
  }
};

export const SmileyQuestion = ({
  question,
  locale,
  error,
  responseValue,
  onChange
}) => {
  const {
    question_id,
    options
  } = question;

  const smileyOptions = useMemo(
    () => options.filter(({ is_opt_out_option }) => !is_opt_out_option),
    [options]
  );

  const optOutOption = useMemo(
    () => options.find(({ is_opt_out_option }) => is_opt_out_option),
    [options]
  );

  const useRadioButtons = false;
  const hasError = error?.question_id == question_id;

  return (
    <FormControl
      error={hasError}
    >
      <RadioGroup
        name={`question-${question_id}-response`}
        value={responseValue}
        disableIcon={!useRadioButtons}
        aria-labelledby={`question-${question_id}-label`}
        spacing={{ xs: 3, md: 5 }}
        component={Stack}
        sx={{ m: 0, mb: 1 }}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 1, md: 2 }}
        >
          {smileyOptions.map((option, index) => (
            <SmileyOption
              key={`question-${question_id}-response-${option.value}`}
              option={option}
              locale={locale}
              question_id={question_id}
              responseValue={responseValue}
              onChange={onChange}
              icon={GET_ICON_BY_SMILEY_INDEX(smileyOptions.length, index)}
            />
          ))}
        </Stack>
        {optOutOption && (
          <SmileyOptOutOption
            option={optOutOption}
            locale={locale}
            responseValue={responseValue}
            onChange={onChange}
          />
        )}
      </RadioGroup>
      <FormHelperText aria-live={'polite'}>
        {hasError && error?.message}
      </FormHelperText>
    </FormControl>
  );
};
