import { useCallback, useMemo, useState } from 'react';
import { Button, Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { SVGComment } from 'icons/survey_taking';
import CommentComponent from './CommentComponent';

const REMOVE_ROW_GAP_FROM_FORM = -4;

const AddComment = (props) => {
  const {
    questionName,
    value,
    comment_helper_text,
    setValue,
    onDebounce,
    onlyShowTextArea,
    disabled
  } = props;

  const { t } = useTranslation();
  const [showTextArea, setShowTextArea] = useState(onlyShowTextArea || value.length > 0);

  const toggleTextArea = useCallback(
    () => {
      if (showTextArea) {
        onDebounce('');
        setValue('');
        setShowTextArea(false);
      } else {
        setShowTextArea(true);
      }
    },
    [showTextArea, setValue, onDebounce]
  );

  const labelText = useMemo(
    () => showTextArea ? t('Remove comment') : t('Add comment'),
    [showTextArea, t]
  );

  const ariaLabel = `${t('Add comment')} ${questionName}`;

  return (
    <Stack
      alignItems={'flex-start'}
      spacing={2}
      sx={{
        width: { xs: '100%' },
        mt: onlyShowTextArea ? REMOVE_ROW_GAP_FROM_FORM : 0
      }}
    >
      {!onlyShowTextArea && (
        <Button
          color={'neutral'}
          variant={'outlined'}
          startDecorator={
            <SVGComment size={16} />
          }
          data-component={'add-comment-button'}
          onClick={toggleTextArea}
        >
          {labelText}
        </Button>
      )}

      {showTextArea && (
        <CommentComponent
          aria-label={ariaLabel}
          minRows={2}
          value={value}
          comment_helper_text={comment_helper_text}
          setValue={setValue}
          onDebounce={onDebounce}
          disabled={disabled}
        />
      )}
    </Stack>
  );
};

export default AddComment;
