import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  FormHelperText,
  RadioGroup,
  Stack,
  accordionClasses
} from '@mui/joy';

import { useCallback, useState } from 'react';
import { SVGTickCircle, SVGError } from 'icons';
import { SUCCESS_COLOR, DANGER_COLOUR } from 'lib/Enum';
import { AccordionMatrixOption } from './AccordionMatrixOption';
import { useSelectedOptions } from './useSelectedOptions';

export const AccordionMatrix = (props) => {
  const {
    question_id,
    options,
    statements,
    locale,
    responseValue,
    displayErrorMessage,
    errorMessage,
    onChange,
    checkForErrors
  } = props;

  const [open, setOpen] = useState();

  const {
    selected,
    onSelectMatrixOption
  } = useSelectedOptions(responseValue, onChange);

  const onToggleAccordion = useCallback(
    (expanded, id) => setOpen(expanded ? id : null),
    []
  );

  return (
    <Stack
      spacing={1}
    >
      <AccordionGroup
        transition={'0.2s ease'}
        sx={{
          [`& .${accordionClasses.root}`]: {
            '& button': {
              paddingLeft: 0,
              paddingRight: 0
            },
            '& button:hover': {
              background: 'transparent',
            },
          }
        }}
      >
        {statements.map(({ name, id }) => {
          const hasSelectedOption = selected.find(({ statement_id }) => statement_id == id) != undefined;
          const hasError = checkForErrors(id);

          return (
            <Accordion
              key={id}
              expanded={open === id}
              onChange={(_, expanded) => onToggleAccordion(expanded, id)}
            >
              <AccordionSummary>
                <Stack
                  direction={'row'}
                  spacing={1}
                  alignItems={'center'}
                >
                  <div style={{ width: '1rem' }}>
                    {(hasError && !hasSelectedOption) && (
                      <SVGError
                        size={16}
                        color={DANGER_COLOUR._500}
                        ariaHidden={'true'}
                      />
                    )}
                    {hasSelectedOption && (
                      <SVGTickCircle
                        size={16}
                        color={SUCCESS_COLOR._500}
                        ariaHidden={'true'}
                      />
                    )}
                  </div>

                  <span id={`matrix-${id}-label`}>{name?.[locale] || name?.en}</span>
                </Stack>
              </AccordionSummary>
              <AccordionDetails
                sx={{ padding: 0 }}
              >
                <RadioGroup
                  name={`matrix-options-${question_id}`}
                  aria-labelledby={`matrix-${id}-label`}
                  data-component={`matrix-${id}-radio-group`}
                  sx={{
                    gap: 2,
                    paddingTop: 1
                  }}
                >
                  {options.map(({ label, value }) => {
                    const checked = selected.some(({ statement_id, response_value }) =>
                      statement_id == id && response_value == value);

                    return (
                      <AccordionMatrixOption
                        id={id}
                        key={value}
                        checked={checked}
                        label={label?.[locale] || label?.en}
                        value={value}
                        onSelectMatrixOption={onSelectMatrixOption}
                        onToggleAccordion={onToggleAccordion}
                      />
                    );
                  })}
                </RadioGroup>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </AccordionGroup>
      <FormHelperText
        sx={{ color: DANGER_COLOUR._500 }}
        aria-live={'polite'}
      >
        {displayErrorMessage && errorMessage}
      </FormHelperText>
    </Stack>
  );
};
