import { RadioGroup, FormControl, FormHelperText, Stack } from '@mui/joy';
import { ZeroTenOption } from './ZeroTenOption';

export const ZeroTen = (props) => {
  const {
    question: {
      question_id,
      options
    },
    locale,
    onChange,
    responseValue,
    error
  } = props;

  const hasError = error?.question_id === question_id;

  return (
    <FormControl
      error={hasError}
    >
      <RadioGroup
        name={`question-${question_id}-response`}
        value={responseValue}
        aria-labelledby={`question-${question_id}-label`}
        sx={{ mb: 1 }}
      >
        <Stack
          direction={{ xs: 'row' }}
          spacing={{ xs: 1, md: 2 }}
          flexWrap={'wrap'}
          useFlexGap
          justifyContent={'center'}
        >
          {options.map(({ label, value }) => (
            <ZeroTenOption
              key={value}
              selected={responseValue == value}
              label={label?.[locale] || label?.en}
              value={value}
              onChange={onChange}
            />
          ))}
        </Stack>
      </RadioGroup>
      <FormHelperText aria-live={'polite'}>
        {hasError && error?.message}
      </FormHelperText>
    </FormControl>
  );
};
