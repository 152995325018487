import { Radio } from '@mui/joy';
import { useCallback } from 'react';

export const TableMatrixOption = (props) => {
  const {
    id,
    value,
    label,
    checked,
    onSelectMatrixOption
  } = props;

  const onChange = useCallback(
    (e) => {
      const { value, checked } = e.target;

      if (checked) {
        onSelectMatrixOption(value, id);
      }
    },
    [id, onSelectMatrixOption]
  );

  return (
    <Radio
      checked={checked}
      value={value}
      onChange={onChange}
      slotProps={{
        input: {
          'aria-label': label,
          name: `matrix-options-${id}`
        }
      }}
    />
  );
};
