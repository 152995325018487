import { Chip, Radio, Typography } from '@mui/joy';
import { SVGDontKnow } from 'icons/survey_taking/';
import { useSmileyOptionDetails } from './useSmileyOptionDetails';

const OptOutLabel = ({ label_name, stroke, fill }) => (
  <Typography
    fontSize={{ xs: 14 }}
    startDecorator={<SVGDontKnow size={16} stroke={stroke} fill={fill} ariaHidden={'true'} />}
  >
    {label_name}
  </Typography>
);

export const SmileyOptOutOption = ({
  option,
  locale,
  responseValue,
  onChange
}) => {

  const {
    value,
    label_name,
    checked,
    fill,
    stroke
  } = useSmileyOptionDetails({
    option,
    locale,
    isHovered: false,
    responseValue,
    isOptOutOption: true
  });

  return (
    <Chip
      color={checked ? 'primary' : 'neutral'}
      variant={checked ? 'solid' : 'plain'}
      sx={{
        '--Chip-minHeight': '40px',
        px: 2,
        py: 1,
      }}
    >
      <Radio
        label={(<OptOutLabel label_name={label_name} fill={fill} stroke={stroke} />)}
        value={value}
        checked={checked}
        variant={checked ? 'solid' : 'outlined'}
        disableIcon
        overlay
        onChange={(event) => {
          if (event.target.checked) {
            onChange(value);
          }
        }}
      />
    </Chip>
  );
};
