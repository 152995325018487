import { MainWrapper } from 'elements/MainWrapper';
import { PageHeaderLoadingSkeleton } from 'elements/PageHeaderLoadingSkeleton';
import { SVGLoadingSpinner } from 'icons/loading';
import { SYSTEM_COLOR } from 'lib/Enum';

export const QuestionsLoadingSkeleton = () => (
  <MainWrapper
    style={{
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    }}
  >
    <PageHeaderLoadingSkeleton />
    <div className={'app__centered'} data-component={'questions-loading'}>
      <SVGLoadingSpinner
        color={SYSTEM_COLOR.PRIMARY}
        size={40}
        style={{ margin: 'auto' }}
      />
    </div>
  </MainWrapper>
);
