import { useState } from 'react';
import { Box, Radio, Stack, Typography } from '@mui/joy';
import { useSmileyOptionDetails } from './useSmileyOptionDetails';

const ICON_SIZES = {
  xs: 24,
  sm: 40,
  md: 64
};

const ICON_BORDER_SIZES = {
  xs: ICON_SIZES.xs + 6,
  sm: ICON_SIZES.sm + 6,
  md: ICON_SIZES.md + 6
};

export const SmileyOption = ({
  option,
  locale,
  icon: Icon,
  responseValue,
  onChange,
}) => {
  const [isHovered, setHovered] = useState(false);
  const {
    value,
    checked,
    label_name,
    fill,
    stroke
  } = useSmileyOptionDetails({
    option,
    locale,
    isHovered,
    responseValue,
    isOptOutOption: false
  });

  const borderColour = checked ? fill : 'white';

  return (
    <Radio
      sx={{
        width: { md: 104 },
        p: { xs: 0, md: 1 }
      }}
      label={(
        <Stack
          direction={{ xs: 'row', md: 'column' }}
          spacing={{ xs: 1, md: 2 }}
          display={'flex'}
          alignItems={'center'}
        >
          <Box
            sx={{
              border: `3px solid ${borderColour}`,
              borderRadius: '100%',
              width: ICON_BORDER_SIZES,
              height: ICON_BORDER_SIZES
            }}
          >
            <Box
              component={Icon}
              sx={{
                width: ICON_SIZES,
                height: ICON_SIZES
              }}
              fill={fill}
              stroke={stroke}
              aria-hidden={'true'}
            />
          </Box>
          <Typography
            sx={{
              fontSize: { xs: 16 },
              textAlign: { xs: 'left', md: 'center' }
            }}
          >
            {label_name}
          </Typography>
        </Stack>
      )}
      slotProps={{
        action: () => ({
          sx: {
            border: 'none',
            '&:hover': {
              bgcolor: 'transparent',
            },
          }
        })
      }}
      disableIcon
      value={value}
      checked={checked}
      onChange={(event) => {
        if (event.target.checked) {
          onChange(value);
        }
      }}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    />
  );
};
