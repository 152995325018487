import { Chip, Radio } from '@mui/joy';
import { useCallback } from 'react';

export const AccordionMatrixOption = ({
  id,
  label,
  value,
  checked,
  onSelectMatrixOption,
  onToggleAccordion
}) => {

  const onChange = useCallback(
    (e) => {
      const { value } = e.target;
      onSelectMatrixOption(value, id);
      onToggleAccordion(false, id);
    },
    [id, onSelectMatrixOption, onToggleAccordion]
  );

  return (
    <Chip
      color={'neutral'}
      variant={'soft'}
      sx={{
        '--Chip-minHeight': '32px',
        px: 2,
        py: 1,
        maxWidth: '100%',
        whiteSpace: 'wrap',

      }}
    >
      <Radio
        checked={checked}
        label={label}
        value={value}
        onChange={onChange}
        overlay
      />
    </Chip>
  );
};
