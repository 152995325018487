import { Box, Stack } from '@mui/joy';
import { useOutletContext, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { PageHeader } from 'elements/PageHeader';
import { useLeavePageDialog, useLocale } from 'hooks';
import { MainWrapper } from 'elements/MainWrapper';

import { CONTAINER_WIDTH } from 'lib/Enum';
import { Question } from './Question';
import { useQuestionsApi } from './useQuestionsApi';
import { QuestionsLoadingSkeleton } from './QuestionsLoadingSkeleton';

export const Questions = () => {
  const { feedbackID } = useParams();
  const [locale] = useLocale();
  const { t } = useTranslation();
  const [useSmoothScroll, setUseSmoothScroll] = useState(false);

  const {
    questions,
    percentage_complete,
    loading,
    answerLoading,
    error,
    onAnswer
  } = useQuestionsApi({ feedbackID });

  const { survey_name } = useOutletContext();

  useLeavePageDialog();

  const questionListRef = useCallback(
    (node) => {
      if (!questions || !questions.length) return;
      const shouldScrollLastQuestionIntoView = questions.length > 1;

      if (shouldScrollLastQuestionIntoView) {
        node?.lastElementChild?.scrollIntoView({
          behavior: useSmoothScroll ? 'smooth' : 'auto'
        });
      }

      node?.lastElementChild?.focus({ preventScroll: true });
      if (!useSmoothScroll) setUseSmoothScroll(true);

    },
    [useSmoothScroll, questions]
  );

  if (loading) return <QuestionsLoadingSkeleton />;

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          zIndex: 1000,
          top: 0,
          left: 0,
          pb: 1,
          width: '100%',
          bgcolor: 'white',
          maxWidth: CONTAINER_WIDTH
        }}
      >
        <PageHeader percentage_complete={percentage_complete} />
      </Box>
      <MainWrapper>
        <h1 className={'visually-hidden'}>
          {survey_name[locale] || survey_name.en}
        </h1>
        <Stack
          className={'questions-list'}
          data-component={'questions-list'}
          spacing={{ xs: 5, md: 10 }}
          ref={questionListRef}
        >
          {questions.map((question, index) => (
            <Question
              key={question.question_id}
              question={question}
              locale={locale}
              hasPreviousQuestion={index > 0}
              isCurrentQuestion={index === questions.length - 1}
              t={t}
              serverError={error}
              answerLoading={answerLoading}
              onAnswer={onAnswer}
            />
          ))}
        </Stack>
      </MainWrapper>
    </>
  );
};

export default Questions;
