import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGSomewhatAgree = forwardRef((props, ref) => {
  const {
    stroke,
    fill
  } = props;
  return (
    <IconWrapper ref={ref} data-component={'svg-somewhat-agree'} {...props} viewBox={'0 0 62 62'}>
      <g stroke={stroke} fill={fill}>
        <path
          d={'M31 61C47.5685 61 61 47.5685 61 31C61 14.4315 47.5685 1 31 1C14.4315 1 1 14.4315 1 31C1 47.5685 14.4315 61 31 61Z'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M47.128 39C45.6379 42.0035 43.3384 44.5312 40.4888 46.2979C37.6392 48.0648 34.3528 49.0005 31 49'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path d={'M21 24C20.4477 24 20 23.5523 20 23C20 22.4477 20.4477 22 21 22'} strokeWidth={'2'} />
        <path d={'M20.9999 24C21.5522 24 21.9999 23.5523 21.9999 23C21.9999 22.4477 21.5522 22 20.9999 22'} strokeWidth={'2'} />
        <path d={'M41 24C40.4477 24 40 23.5523 40 23C40 22.4477 40.4477 22 41 22'} strokeWidth={'2'} />
        <path d={'M40.998 24C41.5503 24 41.998 23.5523 41.998 23C41.998 22.4477 41.5503 22 40.998 22'} strokeWidth={'2'} />
      </g>
    </IconWrapper >
  );
});
