import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGComment = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-comment'} {...props} >
      <g fill={'currentColor'} >
        <path d={'M1.36,22.25A.51.51,0,0,1,1,22.1a.5.5,0,0,1-.08-.57l2.4-4.81A9,9,0,0,1,0,9.89C0,4.43,5.38,0,12,0S24,4.43,24,9.88a.5.5,0,1,1-1,0C23,5,18.07,1,12,1S1,5,1,9.88a8.05,8.05,0,0,0,3.25,6.3.51.51,0,0,1,.15.63L2.45,20.69l5.31-2.47a.49.49,0,0,1,.66.24.5.5,0,0,1-.24.67L1.57,22.2A.5.5,0,0,1,1.36,22.25Z'} />
        <path d={'M23,24a.54.54,0,0,1-.21-.05L19,22.19a9,9,0,0,1-2.37.32h0c-4.08,0-7.4-2.75-7.4-6.13s3.32-6.13,7.4-6.13S24,13,24,16.38a5.68,5.68,0,0,1-1.94,4.16l1.37,2.74a.49.49,0,0,1-.09.57A.48.48,0,0,1,23,24Zm-4-2.85a.54.54,0,0,1,.21.05l2.66,1.24L21,20.62a.51.51,0,0,1,.15-.62A4.61,4.61,0,0,0,23,16.37c0-2.82-2.87-5.12-6.4-5.12s-6.4,2.3-6.4,5.13,2.87,5.13,6.4,5.13h0a7.82,7.82,0,0,0,2.26-.33A.4.4,0,0,1,19,21.15Z'} />
      </g>
    </IconWrapper>
  );
});
