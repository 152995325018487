import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGSomewhatDisagree = forwardRef((props, ref) => {
  const {
    stroke,
    fill
  } = props;
  return (
    <IconWrapper ref={ref} data-component={'svg-somewhat-disagree'} {...props} viewBox={'0 0 62 62'}>
      <g stroke={stroke} fill={fill}>
        <path
          d={'M31 61C47.5685 61 61 47.5685 61 31C61 14.4315 47.5685 1 31 1C14.4315 1 1 14.4315 1 31C1 47.5685 14.4315 61 31 61Z'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M31 47C32.4901 43.9965 34.7896 41.4688 37.6392 39.7021C40.4888 37.9352 43.7752 36.9995 47.128 37'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path d={'M21 24C20.4477 24 20 23.5523 20 23C20 22.4477 20.4477 22 21 22'} strokeWidth={'2'} />
        <path d={'M20.9999 24C21.5522 24 21.9999 23.5523 21.9999 23C21.9999 22.4477 21.5522 22 20.9999 22'} strokeWidth={'2'} />
        <path d={'M40.9941 24C40.4419 24 39.9941 23.5523 39.9941 23C39.9941 22.4477 40.4419 22 40.9941 22'} strokeWidth={'2'} />
        <path d={'M40.9922 24C41.5445 24 41.9922 23.5523 41.9922 23C41.9922 22.4477 41.5445 22 40.9922 22'} strokeWidth={'2'} />
      </g>
    </IconWrapper >
  );
});
