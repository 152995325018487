import * as Sentry from '@sentry/browser';
import { Box, Button, Typography } from '@mui/joy';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { SVGArrow, SVGNext } from 'icons';
import { SVG_ROTATE_ARROW } from 'icons/Utils';
import { HEADER_AND_PROGRESS_BAR_HEIGHT } from 'elements/PageHeader';
import { Explanation } from './Explanation';

import { GroupInfo } from './GroupInfo';
import { SmileyQuestion } from './smiley/SmileyQuestion';
import FreeTextQuestion from './free-text/FreeTextQuestion';
import AddComment from './components/AddComment';
import { YesNoQuestion } from './yes-no/YesNoQuestion';
import { MultiChoice } from './multi-choice/MultiChoice';
import { MultiChoiceHelperText } from './multi-choice/MultiChoiceHelperText';
import { ZeroTen } from './0-10/ZeroTen';
import { Matrix } from './matrix/Matrix';

const MILLI_TO_SECONDS = 1000;

const FREE_TEXT_Q_TYPE = 'free-text';
const CATEGORIZED_Q_TYPE = 'categorized';
const AYT_0_6_Q_TYPE = 'ayt-0-6';
const ZERO_TO_THREE_Q_TYPE = '0-3';
const ZERO_TO_FIVE_Q_TYPE = '0-5';
const ZERO_TO_SIX_Q_TYPE = '0-6';
const ZERO_TO_TEN_Q_TYPE = '0-10';
const YES_NO_Q_TYPE = 'yes-no';
const MULTIPLE_CHOICE_Q_TYPE = 'multiple-choice';
const MATRIX_Q_TYPE = 'matrix';

const MULTIPLE_CHOICE_QUESTION_TYPES = [
  CATEGORIZED_Q_TYPE,
  MULTIPLE_CHOICE_Q_TYPE
];

const SMILEY_QUESTION_TYPES = [
  AYT_0_6_Q_TYPE,
  ZERO_TO_THREE_Q_TYPE,
  ZERO_TO_FIVE_Q_TYPE,
  ZERO_TO_SIX_Q_TYPE
];

const BLANK = '#BLANK#';

export const Question = (props) => {
  const ref = useRef(null);
  const {
    question,
    hasPreviousQuestion,
    isCurrentQuestion,
    locale,
    t,
    serverError,
    answerLoading,
    onAnswer
  } = props;

  const {
    question_id,
    explanation: question_explanation,
    name: question_name = {},
    response_value = BLANK,
    question_type,
    allows_comments,
    has_response,
    comment = '',
    comment_helper_text,
    group_info,
    min_responses_count,
    options
  } = question;

  const {
    description: question_group_description,
  } = group_info || {};

  const hasGroupDescription = question_group_description?.en !== undefined && question_group_description?.en !== '';

  const { response_option_required_comment_enabled } = useOutletContext();

  const multiChoiceWithRequiredComment = response_option_required_comment_enabled && question_type == MULTIPLE_CHOICE_Q_TYPE && options.pluck('comment_required').includes(true);

  const [responseValue, setResponseValue] = useState(response_value);
  const [error, setError] = useState();
  const [commentValue, setCommentValue] = useState(comment);
  const [showQuestion, setShowQuestion] = useState(!hasGroupDescription || (hasGroupDescription && !isCurrentQuestion));
  const [groupDescriptionViewed, setGroupDescriptionViewed] = useState(false);

  const isCommentEnabled = useMemo(
    () => {
      return multiChoiceWithRequiredComment
        ? options.some(option => responseValue?.includes(option.value) && option.comment_required)
        : true;
    },
    [multiChoiceWithRequiredComment, options, responseValue]
  );

  const [commentEnabled, setCommentEnabled] = useState(isCommentEnabled);

  useEffect(
    () => {
      // ref's are null on the first render,
      // this will allow the scroll to work
      // after the group description is viewed the first time
      if (groupDescriptionViewed) {
        ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        ref?.current?.focus({ preventScroll: true });
      }
    },
    [groupDescriptionViewed]
  );

  useEffect(
    () => {
      if (isCommentEnabled) {
        setCommentEnabled(true);
      } else {
        setCommentValue('');
        setCommentEnabled(false);
      }
    },
    [isCommentEnabled]
  );

  const showMultiChoiceHelperText = MULTIPLE_CHOICE_QUESTION_TYPES.includes(question_type)
    && question.max_responses_count > 1;

  const answerStartTime = useMemo(
    () => new Date(),
    []
  );

  const name = useMemo(
    () => {
      let name = question_name[locale];
      if (!name) {
        name = question_name.en;
      }
      return name;
    },
    [locale, question_name]
  );

  const explanation = useMemo(
    () => {
      if (!question_explanation) return null;
      let name = question_explanation[locale];
      if (!name) {
        name = question_explanation.en;
      }
      return name;
    },
    [locale, question_explanation]
  );

  const displayError = useMemo(
    () => {
      if (error) return {
        question_id: error?.question_id,
        message: t(error?.translationKey),
        translationKey: error?.translationKey
      };

      if (question_type === MATRIX_Q_TYPE) {
        return {
          serverError: serverError ? true : false,
          question_id: serverError?.details ? serverError?.details[0]?.question_id : undefined,
          message: serverError?.details
        };
      }

      if (serverError) {
        if (serverError?.details) {
          return {
            question_id: serverError?.details[0]?.question_id,
            message: serverError?.details[0]?.message[locale] || serverError?.details[0]?.message.en
          };
        } else {
          Sentry.captureMessage(JSON.stringify(serverError));
          return;
        }
      }

      return {
        question_id: undefined,
        message: undefined
      };
    },
    [question_type, locale, serverError, error, t]
  );

  const showAddComment = question_type != FREE_TEXT_Q_TYPE && allows_comments;

  const scrollFromGroupToQuestion = useCallback(
    () => {
      setShowQuestion(true);
      // ensure we can trigger the scroll on the first page load
      // but not effect the scroll position when there is no group description
      setGroupDescriptionViewed(true);

      // scroll to the question if the next is clicked a second time
      ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      ref?.current?.focus({ preventScroll: true });
    },
    []
  );

  const backToPreviousQuestion = useCallback(
    () => {
      let prevQuestion = ref.current?.previousElementSibling;
      if (prevQuestion && hasGroupDescription) {
        prevQuestion = prevQuestion.previousElementSibling;
      }
      prevQuestion?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      prevQuestion?.focus({ preventScroll: true });

    },
    [hasGroupDescription]
  );

  const onChangeComment = useCallback(
    () => {
      if (has_response) {
        onAnswer({
          question_id,
          comment: commentValue,
          response_value: null,
          is_auto_save: true
        });
      }
    },
    [commentValue, has_response, question_id, onAnswer]
  );

  const onAddCommentChange = useCallback(
    () => {
      if (has_response) {
        onAnswer({
          question_id,
          response_value: responseValue,
          comment: commentValue,
          is_auto_save: true
        });
      }
    },
    [commentValue, has_response, question_id, responseValue, onAnswer]
  );

  // Set response value to null as the API uses this along with the question type being 'free-text'
  // to determine how to store the data.
  const onFreeTextClick = useCallback(
    () => onAnswer({
      question_id: question_id,
      response_value: null,
      comment: commentValue,
      response_time: (new Date() - answerStartTime) / MILLI_TO_SECONDS,
    }),
    [question_id, commentValue, onAnswer, answerStartTime]
  );

  const onClick = useCallback(
    (e) => {
      setError(undefined);
      e.preventDefault();

      if (question_type == FREE_TEXT_Q_TYPE) {
        onFreeTextClick();
        return;
      }

      if (question_type == MULTIPLE_CHOICE_Q_TYPE && (responseValue == BLANK || responseValue.length < min_responses_count)) {
        if (min_responses_count > 1) {
          setError({
            question_id: question_id,
            translationKey: "You haven't selected enough answers."
          });
        } else {
          setError({
            question_id: question_id,
            translationKey: 'Please select an answer.'
          });
        }
        return;
      }

      if (responseValue == BLANK) {
        setError({
          question_id: question_id,
          translationKey: 'Please select an answer.'
        });
        return;
      }

      const answerData = {
        question_id: question_id,
        response_value: responseValue,
        response_time: (new Date() - answerStartTime) / MILLI_TO_SECONDS
      };

      if (allows_comments) {
        answerData.comment = commentValue;
      }

      onAnswer(answerData);
    },
    [
      onAnswer,
      question_id,
      responseValue,
      answerStartTime,
      question_type,
      allows_comments,
      commentValue,
      onFreeTextClick,
      min_responses_count
    ]
  );

  const onChange = useCallback(
    (value) => {
      setResponseValue(value);
      setError(undefined);

      if (has_response) {
        const answerData = {
          question_id: question_id,
          response_value: value,
          response_time: (new Date() - answerStartTime) / MILLI_TO_SECONDS,
          is_auto_save: true
        };

        onAnswer(answerData);
      }
    },
    [onAnswer, question_id, has_response, answerStartTime]
  );

  return (
    <>
      {hasGroupDescription && (
        <GroupInfo
          group_info={group_info}
          locale={locale}
          t={t}
          onClick={scrollFromGroupToQuestion}
        />
      )}
      {showQuestion && (
        <Box
          component={'section'}
          className={'questions-list__item'}
          data-component={'question'}
          id={`question-${question_id}`}
          ref={ref}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            minHeight: {
              xs: `calc(100vh - ${HEADER_AND_PROGRESS_BAR_HEIGHT.xs} - 24px)`,
              md: `calc(100vh - ${HEADER_AND_PROGRESS_BAR_HEIGHT.md})`
            },
            scrollMarginTop: HEADER_AND_PROGRESS_BAR_HEIGHT,
            scrollSnapMarginTop: HEADER_AND_PROGRESS_BAR_HEIGHT
          }}
        >
          <>
            {hasPreviousQuestion && (
              <Button
                size={'md'}
                type={'button'}
                variant={'text'}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  alignSelf: 'center',
                }}
                onClick={backToPreviousQuestion}
                data-cy={`question-${question_id}-previous-button`}
              >
                <SVGArrow size={16} rotate={SVG_ROTATE_ARROW.UP} ariaHidden={'true'} />
                {t('Previous')}
              </Button>
            )}

            <Box
              component={'form'}
              onSubmit={onClick}
              className={'questions-list__form'}
              sx={{
                flex: 1,
                display: 'flex',
                rowGap: { xs: 2, md: 5 },
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexGrow: 1,
                flexShrink: 1,
                width: { xs: '100%' }
              }}
            >
              <div>
                <Typography
                  level={'h2'}
                  fontSize={{ xs: 16, sm: 18, md: 32 }}
                >
                  <span id={`question-${question_id}-label`}>{name}</span>
                  {explanation && (
                    <Explanation explanation={explanation} />
                  )}
                </Typography>
                {showMultiChoiceHelperText && (
                  <MultiChoiceHelperText
                    min_responses_count={question.min_responses_count}
                    max_responses_count={question.max_responses_count}
                  />
                )}
              </div>

              {SMILEY_QUESTION_TYPES.includes(question_type) && (
                <SmileyQuestion
                  question={question}
                  locale={locale}
                  error={displayError}
                  responseValue={responseValue}
                  onChange={onChange}
                />
              )}
              {MULTIPLE_CHOICE_QUESTION_TYPES.includes(question_type) && (
                <MultiChoice
                  question={question}
                  locale={locale}
                  error={displayError}
                  responseValue={responseValue}
                  onChange={onChange}
                  singleColumn={multiChoiceWithRequiredComment}
                />
              )}
              {question_type === YES_NO_Q_TYPE && (
                <YesNoQuestion
                  question={question}
                  locale={locale}
                  error={displayError}
                  responseValue={responseValue}
                  onChange={onChange}
                />
              )}
              {question_type === ZERO_TO_TEN_Q_TYPE && (
                <ZeroTen
                  question={question}
                  locale={locale}
                  error={displayError}
                  responseValue={responseValue}
                  onChange={onChange}
                />
              )}
              {question_type === MATRIX_Q_TYPE && (
                <Matrix
                  question={question}
                  locale={locale}
                  error={displayError}
                  responseValue={responseValue}
                  t={t}
                  onChange={onChange}
                />
              )}
              {question_type === FREE_TEXT_Q_TYPE && (
                <FreeTextQuestion
                  question_id={question_id}
                  value={commentValue}
                  comment_helper_text={comment_helper_text}
                  setValue={setCommentValue}
                  onDebounce={onChangeComment}
                />
              )}
              {showAddComment && (
                <AddComment
                  questionName={name}
                  value={commentValue}
                  comment_helper_text={comment_helper_text}
                  setValue={setCommentValue}
                  onDebounce={onAddCommentChange}
                  onlyShowTextArea={multiChoiceWithRequiredComment}
                  disabled={!commentEnabled}
                />
              )}

              <Button
                size={'lg'}
                type={'submit'}
                loadingPosition={'end'}
                loading={answerLoading}
                endDecorator={(
                  <SVGNext
                    style={{ transform: 'rotate(90deg)' }}
                    size={24}
                    ariaHidden={'true'}
                  />
                )}
                onClick={onClick}
                data-cy={`submit-question-${question_id}`}
                sx={{
                  alignSelf: 'flex-end',
                }}
              >
                {t('Next')}
              </Button>
            </Box>
          </>
        </Box>
      )}
    </>
  );
};
