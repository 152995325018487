import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGStronglyDisagree = forwardRef((props, ref) => {
  const {
    stroke,
    fill
  } = props;
  return (
    <IconWrapper ref={ref} data-component={'svg-strongly-disagree'} {...props} viewBox={'0 0 62 62'}>
      <g stroke={stroke} fill={fill}>
        <path
          d={'M1 31C1 38.9565 4.16071 46.5871 9.7868 52.2132C15.4129 57.8393 23.0435 61 31 61C38.9565 61 46.5871 57.8393 52.2132 52.2132C57.8393 46.5871 61 38.9565 61 31C61 23.0435 57.8393 15.4129 52.2132 9.7868C46.5871 4.16071 38.9565 1 31 1C23.0435 1 15.4129 4.16071 9.7868 9.7868C4.16071 15.4129 1 23.0435 1 31V31Z'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M45.0021 28.656L37.0021 23L45.0021 17.344'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M16.9961 17.3438L24.9961 22.9998L16.9961 28.6558'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M46.9971 46.9994C45.5076 43.9934 43.2079 41.4633 40.3573 39.6944C37.5067 37.9256 34.2186 36.9883 30.8638 36.9883C27.509 36.9883 24.2209 37.9256 21.3703 39.6944C18.5197 41.4633 16.22 43.9934 14.7305 46.9994'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
      </g>
    </IconWrapper>
  );
});
