import { Stack } from '@mui/joy';
import CommentComponent from '../components/CommentComponent';

const FreeTextQuestion = (props) => {
  const {
    question_id,
    value,
    comment_helper_text,
    setValue,
    onDebounce
  } = props;

  return (
    <Stack
      spacing={1}
      alignItems={'flex-start'}
      sx={{
        width: { xs: '100%' }
      }}
    >
      <CommentComponent
        aria-labelledby={`question-${question_id}-label`}
        value={value}
        comment_helper_text={comment_helper_text}
        setValue={setValue}
        onDebounce={onDebounce}
      />
    </Stack>
  );
};

export default FreeTextQuestion;
