import { Radio, radioClasses } from '@mui/joy';

export const ZeroTenOption = ({
  selected,
  label,
  value,
  onChange,
}) => (
  <Radio
    disableIcon
    variant={selected ? 'solid' : 'outlined'}
    color={selected ? 'primary' : 'neutral'}
    label={label}
    value={value}
    checked={selected}
    onChange={(event) => {
      if (event.target.checked) {
        onChange(value);
      }
    }}
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: { xs: 48 },
      height: { xs: 48 },
      [`& .${radioClasses.action}`]: {
        borderWidth: '2px',
      },
    }}
    slotProps={{
      radio: {
        sx: {
          borderRadius: '50%',
        },
      },
    }}
  />
);
