export const useYesNoOptionDetails = ({
  option,
  locale,
  responseValue
}) => {
  const {
    value,
    label
  } = option;

  let {
    [locale]: label_name
  } = label || {};

  if (!label_name || label_name == '') {
    label_name = label.en;
  }

  const checked = responseValue == value;

  return {
    value,
    checked,
    label_name
  };
};
