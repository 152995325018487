import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGStronglyAgree = forwardRef((props, ref) => {
  const {
    stroke,
    fill
  } = props;
  return (
    <IconWrapper ref={ref} data-component={'svg-strongly-agree'} {...props} viewBox={'0 0 62 62'}>
      <g stroke={stroke} fill={fill}>
        <path
          d={'M1 31C1 38.9565 4.16071 46.5871 9.7868 52.2132C15.4129 57.8393 23.0435 61 31 61C38.9565 61 46.5871 57.8393 52.2132 52.2132C57.8393 46.5871 61 38.9565 61 31C61 23.0435 57.8393 15.4129 52.2132 9.7868C46.5871 4.16071 38.9565 1 31 1C23.0435 1 15.4129 4.16071 9.7868 9.7868C4.16071 15.4129 1 23.0435 1 31V31Z'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M14.0212 37C13.7013 37 13.386 37.0768 13.1019 37.224C12.8178 37.3711 12.5731 37.5842 12.3884 37.8454C12.2037 38.1067 12.0844 38.4084 12.0404 38.7253C11.9965 39.0422 12.0292 39.365 12.1359 39.6667C13.5124 43.5696 16.0657 46.9494 19.4439 49.3401C22.8221 51.7307 26.8587 53.0146 30.9972 53.0146C35.1357 53.0146 39.1724 51.7307 42.5505 49.3401C45.9287 46.9494 48.482 43.5696 49.8585 39.6667C49.9652 39.365 49.9979 39.0422 49.954 38.7253C49.91 38.4084 49.7907 38.1067 49.606 37.8454C49.4213 37.5842 49.1766 37.3711 48.8925 37.224C48.6084 37.0768 48.2931 37 47.9732 37H14.0212Z'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M48.3166 26.9994C47.9032 25.8293 47.1372 24.8161 46.124 24.0995C45.1108 23.3829 43.9003 22.998 42.6593 22.998C41.4183 22.998 40.2078 23.3829 39.1946 24.0995C38.1814 24.8161 37.4153 25.8293 37.002 26.9994'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M25.0002 26.9994C24.5868 25.8293 23.8208 24.8161 22.8076 24.0995C21.7944 23.3829 20.5839 22.998 19.3429 22.998C18.1019 22.998 16.8914 23.3829 15.8782 24.0995C14.865 24.8161 14.0989 25.8293 13.6855 26.9994'} strokeWidth={'2'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />

      </g>
    </IconWrapper>
  );
});
