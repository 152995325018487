import { Chip, Radio } from '@mui/joy';
import { useYesNoOptionDetails } from './useYesNoOptionDetails';

export const YesNoOption = ({
  option,
  locale,
  responseValue,
  onChange
}) => {
  const {
    value,
    checked,
    label_name
  }  = useYesNoOptionDetails({
    option,
    locale,
    responseValue
  });

  return (
    <Chip
      sx={{
        '--Chip-minHeight': '48px',
        '--Chip-paddingInline': '32px'
      }}
    >
      <Radio
        variant={checked ? 'solid' : 'outlined'}
        color={checked ? 'primary' : 'neutral'}
        disableIcon
        overlay
        label={label_name}
        value={value}
        checked={checked}
        onChange={(event) => {
          if (event.target.checked) {
            onChange(value);
          }
        }}
      />
    </Chip>
  );
};
