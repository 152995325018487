import { Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';

const FONT_SIZE = 'body-xs';
const MARGIN_TOP = 1;

export const MultiChoiceHelperText = ({ min_responses_count, max_responses_count }) => {
  const { t } = useTranslation();

  if (min_responses_count == max_responses_count) return (
    <Typography
      level={FONT_SIZE}
      mt={MARGIN_TOP}
    >
      {t(
        'Select {{max_responses_count}} answers.',
        {
          max_responses_count: max_responses_count
        }
      )}
    </Typography>
  );

  if (min_responses_count > 1) return (
    <Typography
      level={FONT_SIZE}
      mt={MARGIN_TOP}
    >
      {t(
        'Select between {{min_responses_count}} to {{max_responses_count}} answers.',
        {
          min_responses_count: min_responses_count,
          max_responses_count: max_responses_count
        }
      )}
    </Typography>
  );

  return (
    <Typography
      level={FONT_SIZE}
      mt={MARGIN_TOP}
    >
      {t(
        'Select up to {{max_responses_count}} answers.',
        { max_responses_count: max_responses_count }
      )}
    </Typography>
  );
};
