import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/joy';
import { ToggleTooltip } from 'elements/tool-tip';
import { SVGInfo } from 'icons';

export const Explanation = ({ explanation }) => {
  const theme = useTheme();

  return (
    <ToggleTooltip
      sx={{
        maxWidth: {
          xs: 300,
          sm: theme.breakpoints.values.sm - 16,
          md: theme.breakpoints.values.md - 16
        },
        p: 1
      }}
      placement={'bottom'}
      buttonProps={{ sx: { marginLeft: 1 } }}
      tooltipContent={<Typography>{explanation}</Typography>}
      ariaLabel={'Select for an explanation of this question'}
    >
      <Box
        component={SVGInfo}
        sx={{
          width: { xs: 16, md: 24 },
          height: { xs: 16, md: 24 },
        }}
      />
    </ToggleTooltip>
  );
};
