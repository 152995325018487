import { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Textarea, FormHelperText } from '@mui/joy';
import { useLocale } from 'hooks';

const DEBOUNCE_TIMEOUT = 400;

const DebounceTextArea = (props) => {
  const {
    debounceTimeout,
    value,
    setValue,
    handleDebounce,
    disabled,
    ...rest
  } = props;

  const handleChange = useCallback(
    (event) => {
      setValue(event.target.value);
    },
    [setValue]
  );

  const firstRender = useRef(true);

  useEffect(
    () => {
      if (firstRender.current) {
        firstRender.current = false;
        return;
      }
      const timeoutId = setTimeout(
        () => {
          handleDebounce(value);
        },
        debounceTimeout
      );
      return () => clearTimeout(timeoutId);
    },
    [value, debounceTimeout, handleDebounce]
  );

  return (
    <Textarea
      data-component={'comment-text-area'}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      {...rest}
      sx={{
        width: { xs: '100%' }
      }}
    />
  );
};

DebounceTextArea.propTypes = {
  debounceTimeout: PropTypes.number.isRequired,
  handleDebounce: PropTypes.func.isRequired,
  minRows: PropTypes.number
};

DebounceTextArea.defaultProps = {
  minRows: 4
};

const CommentComponent = ({ comment_helper_text, onDebounce, ...rest }) => {
  const [locale] = useLocale();

  return (
    <div style={{ width: '100%' }}>
      <FormHelperText
        sx={{
          marginBottom: 1
        }}
        id={'comment-helper-text'}
      >
        {comment_helper_text[locale] || comment_helper_text?.en}
      </FormHelperText>

      <DebounceTextArea
        {...rest}
        debounceTimeout={DEBOUNCE_TIMEOUT}
        handleDebounce={onDebounce}
      />
    </div>
  );
};

export default CommentComponent;
