import { FormControl, FormHelperText, RadioGroup } from '@mui/joy';
import { YesNoOption } from './YesNoOption';

export const YesNoQuestion = ({
  question,
  locale,
  error,
  responseValue,
  onChange
}) => {
  const {
    question_id,
    options
  } = question;

  const hasError = error?.question_id === question_id;

  return (
    <FormControl
      error={hasError}
    >
      <RadioGroup
        name={`question-${question_id}-response`}
        aria-labelledby={`question-${question_id}-label`}
        orientation={'vertical'}
        sx={{ flexWrap: 'wrap', gap: 4 }}
      >
        {options.map((option) =>(
          <YesNoOption
            key={`question-${question_id}-response-${option?.value}`}
            option={option}
            locale={locale}
            responseValue={responseValue}
            onChange={onChange}
          />
        ))}
      </RadioGroup>
      <FormHelperText aria-live={'polite'}>
        {hasError && error?.message}
      </FormHelperText>
    </FormControl>
  );
};
