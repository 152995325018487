import { useTheme } from '@emotion/react';
import { useMemo } from 'react';

export const useSmileyOptionDetails = ({
  option,
  locale,
  isHovered,
  responseValue,
  isOptOutOption
}) => {
  const {
    value,
    label
  } = option;

  let {
    [locale]: label_name
  } = label || {};

  if (!label_name || label_name == '') {
    label_name = label.en;
  }

  const theme = useTheme();
  const checked = responseValue == value;

  const {
    stroke,
    fill
  } = useMemo(
    () => {
      let stroke = theme.palette.primary[500];
      let fill = theme.palette.common.white;

      if (!checked && isHovered) {
        fill = theme.palette.primary.softBg;
      } else if (checked) {
        fill = isOptOutOption ? theme.palette.primary.softBg : theme.palette.primary[500];
        stroke = isOptOutOption ? theme.palette.primary[500] : theme.palette.common.white;
      }

      return {
        stroke,
        fill
      };
    },
    [checked, theme, isHovered, isOptOutOption]
  );

  return {
    value,
    checked,
    label_name,
    stroke,
    fill
  };
};
