import { Button, Stack, Typography } from '@mui/joy';
import { useMemo } from 'react';
import { SVGNext } from 'icons';
import { HEADER_AND_PROGRESS_BAR_HEIGHT } from 'elements/PageHeader';

export const GroupInfo = ({
  group_info: {
    description: question_group_description,
  } = {},
  locale,
  t,
  onClick
}) => {

  const groupDescription = useMemo(
    () => {
      if (!question_group_description) return null;
      return question_group_description[locale] || question_group_description.en;
    },
    [locale, question_group_description]
  );

  return (
    <Stack
      component={'section'}
      className={'questions-list__item'}
      data-component={'question-group'}
      spacing={5}
      sx={{
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexGrow: 1,
        flexShrink: 1,
        minHeight: {
          xs: `calc(100vh - ${HEADER_AND_PROGRESS_BAR_HEIGHT.xs} - 24px)`,
          md: `calc(100vh - ${HEADER_AND_PROGRESS_BAR_HEIGHT.md})`
        },
        scrollMarginTop: HEADER_AND_PROGRESS_BAR_HEIGHT,
        scrollSnapMarginTop: HEADER_AND_PROGRESS_BAR_HEIGHT,
      }}
    >
      <Typography
        level={'h1'}
        fontSize={24}
      >
        {groupDescription}
      </Typography>
      <Button
        size={'lg'}
        type={'button'}
        endDecorator={<SVGNext style={{ transform: 'rotate(90deg)' }} size={24} />}
        onClick={onClick}
        data-component={'question-group-button'}
        sx={{
          alignSelf: 'flex-end',
        }}
      >
        {t('Next')}
      </Button>
    </Stack>
  );
};
