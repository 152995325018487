import { useCallback, useState } from 'react';

export const useSelectedOptions = (responseValue, onChange) => {
  const [selected, setSelected] = useState(responseValue === '#BLANK#' ? [] : responseValue);

  const onSelectMatrixOption = useCallback(
    (value, id) => {
      const response = {
        statement_id: id,
        response_value: value
      };

      const updated = [
        ...selected.filter(({ statement_id }) => statement_id !== id),
        { ...response }
      ];

      setSelected(updated);
      onChange(updated);
    },
    [selected, onChange]
  );

  return { selected, onSelectMatrixOption };
};
