import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGAgree = forwardRef((props, ref) => {
  const {
    stroke,
    fill
  } = props;
  return (
    <IconWrapper ref={ref} data-component={'svg-agree'} {...props} viewBox={'0 0 62 62'}>
      <g stroke={stroke} fill={fill}>
        <path d={'M31 61C47.5685 61 61 47.5685 61 31C61 14.4315 47.5685 1 31 1C14.4315 1 1 14.4315 1 31C1 47.5685 14.4315 61 31 61Z'} strokeWidth={'2'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path d={'M21 24C20.4477 24 20 23.5523 20 23C20 22.4477 20.4477 22 21 22'} strokeWidth={'2'} />
        <path d={'M20.9999 24C21.5522 24 21.9999 23.5523 21.9999 23C21.9999 22.4477 21.5522 22 20.9999 22'} strokeWidth={'2'} />
        <path d={'M41 24C40.4477 24 40 23.5523 40 23C40 22.4477 40.4477 22 41 22'} strokeWidth={'2'} />
        <path d={'M40.9998 24C41.552 24 41.9998 23.5523 41.9998 23C41.9998 22.4477 41.552 22 40.9998 22'} strokeWidth={'2'} />
        <path d={'M47.128 39C45.6384 42.0061 43.3387 44.536 40.4883 46.3051C37.6376 48.0739 34.3496 49.0112 30.9947 49.0112C27.6397 49.0112 24.3518 48.0739 21.5011 46.3051C18.6506 44.536 16.3508 42.0061 14.8613 39'} strokeWidth={'2'} strokeLinecap={'round'} strokeLinejoin={'round'} />
      </g>
    </IconWrapper >
  );
});
